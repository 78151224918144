<template>
  <div>
    <vx-card title="Find nearby clinics for suppliers" class="mt-base">
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="8">
              <div class="w-full mt-5 mr-5">
                <label>Select supplier</label>
                <v-select
                  :options="supplierOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="selectedSupplier"
                  data-vv-as="Selected Supplier"
                  data-vv-scope="basic"
                  name="selectSupplier"
                />
                <span class="text-danger" style="font-size: 0.85em">{{
                    errors.first("selectSupplier")
                  }}</span>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
              <div class="w-full m-5">
                <vs-select
                  label="Radius"
                  placeholder="5"
                  autocomplete
                  v-model="dataTableParams.radiusLimit"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in radiusLimitOptions"
                    :clearable="false"
                  />
                </vs-select>
              </div>
            </vs-col>
          </vs-row>
          <!--          <div class="w-4/5 mt-5 mb-5">
                      <label>Select supplier</label>
                      <v-select
                        :options="supplierOptions"
                        :clearable="false"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="selectedSupplier"
                         data-vv-as="selectedSupplier"
                        data-vv-scope="basic"
                        name="selectedSupplier"
                    />
                      <span class="text-danger" style="font-size: 0.85em">{{
                          errors.first("selectedSupplier")
                        }}</span>
                    </div>
                    <div class="w-1/5 m-5">
                      <vs-select
                        label="Radius"
                        placeholder="5"
                        autocomplete
                        v-model="dataTableParams.radiusLimit"
                      >
                        <vs-select-item
                          :key="index"
                          :value="item.value"
                          :text="item.text"
                          v-for="(item, index) in radiusLimitOptions"
                          :clearable="false"
                        />
                      </vs-select>
                    </div>-->
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="12">
          <div class="w-full mt-5 mb-5">
            <vs-button
              :disabled="!validateForm"
              icon-pack="feather"
              icon="icon-search"
              @click="getNearbyClinics"
            >Find Now</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <vx-card title="Nearby Clinics" class="mt-base" >
      <div>
        <div id="data-list-list-view" class="data-list-container">
          <vs-table
            ref="table"
            :sst="true"
            :total="totalDocs"
            :data="searchResult"
            @search="handleSearch"
            @change-page="handleChangePage"
            @sort="handleSort"
            :max-items="dataTableParams.limit"
            search
            :noDataText="noDataText"
          >
            <div
              slot="header"
              class="flex flex-wrap-reverse flex-grow justify-between"
            >
              <div style="float: left">
                <vs-select
                  label="Radius"
                  placeholder="5"
                  vs-multiple
                  autocomplete
                  v-model="dataTableParams.limit"
                  class="mr-6"
                >
                  <vs-select-item
                    :key="index"
                    :value="item.value"
                    :text="item.text"
                    v-for="(item, index) in limitOptions"
                    :clearable="false"
                  />
                </vs-select>
              </div>
            </div>

            <template slot="thead">
              <vs-th sort-key="companyName">Company Name</vs-th>
              <vs-th sort-key="abn">Company ABN</vs-th>
              <vs-th sort-key="clinicName">Clinic Name</vs-th>
              <vs-th sort-key="address.displayAddress">Street Address</vs-th>
              <vs-th sort-key="postalAddress">Postal Address</vs-th>
              <vs-th sort-key="email">Email</vs-th>
              <vs-th sort-key="status">Status</vs-th>
              <vs-th sort-key="hasApprovedByAdmin">Admin Approval</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].companyName">{{
                    data[indextr].companyName | capitalize
                  }}</vs-td>
                <vs-td :data="data[indextr].abn">{{ data[indextr].abn }}</vs-td>
                <vs-td :data="data[indextr].clinicName">{{
                    data[indextr].clinicName | capitalize
                  }}</vs-td>
                <vs-td>
                  <div v-if="data[indextr].address">
                    {{ data[indextr].address.displayAddress }}
                  </div>
                  <div v-else>N/A</div>
                </vs-td>
                <vs-td :data="data[indextr].postalAddress">{{
                    data[indextr].postalAddress || "N/A"
                  }}</vs-td>
                <vs-td :data="data[indextr].email">{{ data[indextr].email }}</vs-td>

                <vs-td :data="data[indextr].status">
                  <p
                    v-if="data[indextr].status === 'Pending_Approval'"
                    class="text-sm"
                  >
                    Pending Approval
                  </p>
                  <p v-else class="text-sm">{{ data[indextr].status }}</p>
                </vs-td>
                <vs-td>{{ data[indextr].hasApprovedByAdmin }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";

export default {
  name: "FindNearbyClinics",
  components: {
    vSelect,
  },
  data: () => {
    return {
      supplierData: [],
      supplierOptions: [],
      selectedSupplier: null,
      isMounted: false,
      currentPage: 1,
      totalDocs: 0,
      page: 1,
      noDataText: "Loading...",
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10,
        radiusLimit: 5,
        supplierId: "",
      },
      radiusLimitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
      ],
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      serverResponded: false,
      awaitingSearch: null,
      searchResult: [],
      checkValidation: true,
    };
  },
  computed: {
    validateForm() {
      return this.selectedSupplier !== {};
    },
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
      parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  methods: {
    ...mapActions("supplier", ["getAllSupplierList", "searchNearbyClinics"]),
    async getAllSuppliersList() {
      let self = this;
      self.$vs.loading();
      await this.getAllSupplierList()
        .then(async (res) => {
          self.supplierData = res.data.data;
          self.supplierOptions = await self.supplierData.map((item) => {
            return {
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              value: item._id,
            };
          });
          self.$vs.loading.close();
        })
        .catch((err) => {
          self.$vs.loading.close();
        });
    },
    async getNearbyClinics() {
      let self = this;
      let valid = true;

      if (this.selectedSupplier === null) {
        this.error.add({
          field: "selectedSupplier",
          msg: "You must select supplier to search",
        });
        valid = false;
        return valid;
      }
      if(valid) {
        this.dataTableParams.supplierId = this.selectedSupplier.value;
        this.$vs.loading();
        await this.searchNearbyClinics(this.dataTableParams)
          .then((res) => {
            this.searchResult = res.data.data.docs;
            self.totalDocs = res.data.data.pagination.total || 0;
            self.page = res.data.data.pagination.page;
            self.currentPage = res.data.data.pagination.page;
            self.noDataText = "No Clinics Available";
            this.$vs.loading.close();
          })
          .catch(err => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Failed to Find Clinic",
              text: err.data.message,
              color:"danger"
            });
          });
      }
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getNearbyClinics();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getNearbyClinics();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getNearbyClinics();
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getNearbyClinics();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getNearbyClinics();
      }
    },
    "selectedSupplier": function (val) {
      if (val.value !== null) {
        this.dataTableParams.supplierId = val.value;
        this.errors.remove("selectedSupplier");
      }
    },
  },
  async created() {
    await this.getAllSuppliersList();
  },
};
</script>
